<template>
  <el-switch
    style="display: block;margin: 30px;"
    v-model="listQuery.type"
    active-color="#13ce66"
    inactive-color=""
    active-text="更新"
    inactive-text=""
    @click="dialog"
  >
  </el-switch>
  <el-dialog :close-on-click-modal="false"
    title="提示"
    v-model="dialogVisible"
    width="30%"
    :before-close="UpdateCell"
    destroy-on-close
   >
  <span>
    <!-- <vWangeditor @change="changeContent" ref="text" :content="listQuery.content" class="myQuillEditor" :options="editorOption"></vWangeditor> -->
    <el-input class="GXupdate" type="textarea" v-model="listQuery.content" style="height: 150px;"></el-input>
  </span>
  <template #footer>
    <span class="dialog-footer">
      <el-button @click="UpdateCell" size="small">取 消</el-button>
      <el-button type="primary" @click="UpdateInfo" size="small">确 定</el-button>
    </span>
  </template>
</el-dialog>
</template>

<script>
import { systemUpdate,systemUpdateInfo } from '@/api/org.js'
export default {
  data() {
    return {
      listQuery: {
        type: false,
        content:''
      },
      dialogVisible: false,
      editorOption: {},
    }
    
  },
  created() {
    this.getList()
  },
  methods: {
    dialog() {
      // if(this.listQuery.value == true) {
        this.dialogVisible = true
      // }
      
    },
    changeContent(e){
      this.listQuery.content = e.html
    },
    UpdateCell() {
      this.getList()
      this.dialogVisible = false
    },
    UpdateInfo() {
      systemUpdate(this.listQuery).then(res=>{
        if(res.data.msg == "success") {
          this.$message({
            message: '更新成功',
            type: 'success'
          });
          this.getList()
          this.dialogVisible = false
        }
      })
    },
    getList() {
      systemUpdateInfo().then(res => {
        console.log(res.data.data,'res')
        this.listQuery.type = res.data.data
      })
    }
  }
}
</script>

<style lang="scss">
.GXupdate .el-textarea__inner {
  height: 150px !important;
}
</style>